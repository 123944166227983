<template>
  <div class="readme-article">
    <h1 id="系统搭建"></h1>
    <h2 id="系统使用前配置">系统使用前配置</h2>
    <p>
      系统使用前需要先配置机构的一些基础信息，包括专业院校配置、教务配置、财务配置、招生配置、其他等基础配置
    </p>
    <p>
      <img src="@/assets/img/student/config0-0.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Student0-0",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>